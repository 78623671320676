import {memo, useContext, useEffect} from "react"
import style from '@/styles/map.module.css'
import dynamic from 'next/dynamic'
import Aos from 'aos'
import 'aos/dist/aos.css'
//import Slider from "./components/slider"
import useLocale from '../../hooks/useLocale'
import ThemeContext from '../../context/themeContext'

const PopUpCyberArea = dynamic(() => import("./components/Map/components/Circles/components/CyberArea/components/PopUpCyberArea"))
const PopUpFactory = dynamic(() => import("./components/Map/components/Circles/components/ElectricFactory/PopUpFactory"))
const PopUpScreenshots = dynamic(() => import("../content/components/Screenshots/components/PopUpScreenshots"))
const Play = dynamic(() => import("./components/Play"))
//const SinceTimer = dynamic(() => import("./components/SinceTimer"))
const Advantages = dynamic(() => import("./components/Advantages"))
const Map = dynamic(() => import("./components/Map"))
const Team = dynamic(() => import("./components/Team"))
const Partners = dynamic(() => import("./components/Partners"))
const Circles = dynamic(() => import('./components/Map/components/Circles'))
const PopUpMining = dynamic(() => import('./components/Map/components/Circles/components/MiningAndAirdropArea/components/PopUpMining'))
const PopUpMiningMobile = dynamic(() => import('./components/Map/components/Circles/components/MiningAndAirdropArea/components/PopUpMiningMobile'))
const PopUpCyberAreaMobile = dynamic(() => import('./components/Map/components/Circles/components/CyberArea/components/PopUpCyberAreaMobile'))
const PopUpFactoryMobile = dynamic(() => import('./components/Map/components/Circles/components/ElectricFactory/PopUpFactoryMobile'))
const PopUpSlum = dynamic(() => import('./components/Map/components/Circles/components/SlumVille/components/PopUpSlum'))
const PopUpSlumMobile = dynamic(() => import('./components/Map/components/Circles/components/SlumVille/components/PopUpSlumMobile'))
const PopUpTech = dynamic(() => import('./components/Map/components/Circles/components/TechVille/components/PopUpTech'))
const PopUpTechMobile = dynamic(() => import('./components/Map/components/Circles/components/TechVille/components/PopUpTechMobile'))
const PopUpLeaw = dynamic(() => import('./components/Map/components/Circles/components/LeawBeach/components/PopUpLeaw'))
const PopUpLeawMobile = dynamic(() => import('./components/Map/components/Circles/components/LeawBeach/components/PopUpLeawMobile'))
const PopUpTesla = dynamic(() => import('./components/Map/components/Circles/components/TeslaCoil/components/PopUpTesla'))
const PopUpTeslaMobile = dynamic(() => import('./components/Map/components/Circles/components/TeslaCoil/components/PopUpTeslaMobile'))
const PopUpTribe = dynamic(() => import('./components/Map/components/Circles/components/Tribe/components/PopUpTribe'))
const PopUpTribeMobile = dynamic(() => import('./components/Map/components/Circles/components/Tribe/components/PopUpTribeMobile'))
const Screenshots = dynamic(() => import('./components/Screenshots'))
const Blog = dynamic(() => import('./components/Blog'))
const NFT = dynamic(() => import('./components/NFT'))
const Soon = dynamic(() => import('./components/Soon'))
const Token = dynamic(() => import('./components/Token'))
const PlayToEarn = dynamic(() => import('./components/Play2Earn'))
const Characters = dynamic(() => import('./components/Charaters'))
const PopUpYoutube = dynamic(() => import('./components/Play/PopUpYoutube'))
const Roadmap = dynamic(() => import('./components/Roadmap'))
const Advisory = dynamic(() => import('./components/Advisory'))
const LearnTheStory = dynamic(() => import('./components/LearnTheStory'))
const Subscribe = dynamic(() => import('./components/Subscribe'))

const Content = () => {
	const {mapSection} = useLocale()
  const {activeMining, activeMiningMobile, activeFactory, activeFactoryMobile, activeCyber, activeCyberMobile, activeSlum, activeSlumMobile, activeTech, activeTechMobile, activeTesla, activeTeslaMobile, activeLeaw, activeLeawMobile, activeYoutube, popUpScreenActive, activeTribe, activeTribeMobile} = useContext(ThemeContext)

	useEffect(() => {
		Aos.init()
	}, [])

	return (
		<>
			<Play/>			
			<Subscribe/>
			
			<div id="features" className={style.mapBG}>


				<Map/>
				<Circles/>
				<div className={style.mobileBlocks}>
					<div className={style.blockMainTexts}>
						<div
							data-aos-once="true" data-aos="fade-right"
							data-aos-easing="ease-in-sine"
							className={style.blockAbout}>
							<h3 className={style.mobileTitle}>
								{mapSection.firstAdvantageTitle}
							</h3>
							<p className={style.mobileText}>
								{mapSection.firstAdvantageText}
							</p>
						</div>
						<div
							data-aos-once="true" data-aos="fade-right"
							data-aos-easing="ease-in-sine"
							className={style.blockAbout}>
							<h3 className={style.mobileTitle}>
								{mapSection.secondAdvantageTitle}
							</h3>
							<p className={style.mobileText}>
								{mapSection.secondAdvantageText}
							</p>
						</div>
						<div
							data-aos-once="true" data-aos="fade-right"
							data-aos-easing="ease-in-sine"
							className={style.blockAbout}>
							<h3 className={style.mobileTitle}>
								{mapSection.thirdAdvantageTitle}
							</h3>
							<p className={style.mobileText}>
								{mapSection.thirdAdvantageText}
							</p>
						</div>
					</div>
				</div>
				<Screenshots/>
			</div>
			<LearnTheStory/>
			<NFT/>
			<div className={style.bgPlay}>
				<PlayToEarn/>
			</div>
			<div className={style.bgSoon}>
				<Soon/>
			</div>
			<div className={style.bgRoadmap}>
				<div className="fadeRevert"/>
				<Roadmap/>
			</div>
			{/*<Token />*/}
			<Blog/>
			<div className={style.bgPartners}>
				<Team/>
				<Advisory/>
				<Partners/>
			</div>
			{!activeMining && <PopUpMining/>}
			{!activeMiningMobile && <PopUpMiningMobile/>}
			{!activeFactory && <PopUpFactory/>}
			{!activeFactoryMobile && <PopUpFactoryMobile/>}
			{!activeCyber && <PopUpCyberArea/>}
			{!activeCyberMobile && <PopUpCyberAreaMobile/>}
			{!popUpScreenActive && <PopUpScreenshots/>}
			{!activeSlum && <PopUpSlum/>}
			{!activeSlumMobile && <PopUpSlumMobile/>}
			{!activeTech && <PopUpTech/>}
			{!activeTechMobile && <PopUpTechMobile/>}
			{!activeLeaw && <PopUpLeaw/>}
			{!activeLeawMobile && <PopUpLeawMobile/>}
			{!activeTesla && <PopUpTesla/>}
			{!activeTeslaMobile && <PopUpTeslaMobile/>}
			{!activeYoutube && <PopUpYoutube/>}
			{!activeTribe && <PopUpTribe/>}
			{!activeTribeMobile && <PopUpTribeMobile/>}
		</>
	)
}

export default memo(Content)
