import type {NextPage} from 'next'
import Header from '../components/Header'
import Content from 'components/content'
import Footer from 'components/Footer'

const Home: NextPage = () => {
	return (
		<>
			<Header title='Metaisland The Crypto Metaverse' />
			<Content/>
			<Footer/>
		</>
	)
}

export default Home
